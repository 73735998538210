import React, { useEffect, useState } from "react";
import $ from "jquery";
import { gsap } from "gsap";
import RulesModal from "./RulesModal";

// note (cardPerDeck / cardsPerSuit) must be equal to suitType.length // Eg: 4
// otherwise cardData creation will fail

let cardData = [];

const cardsperDeck = 52;
const cardsPerSuit = 13;
const suitStarting = 2;
const suitType = ["s", "d", "h", "c"];
const warCardsCount = 3;

let compareCardsObj = {
  player: [],
  opponent: [],
};

let cardsOnHand = {
  player: [],
  opponent: [],
  playerExtra: [],
  opponentExtra: [],
  warMode: false,
  warCount: 0,
};

let cardSize = {
  deckSize: {
    // width: 138,
    // height: 188,
    width: 80,
    height: 125,
  },
  playerSize: {
    width: 69,
    height: 98,
  },
};

const classes = {
  cardDeckLayout: "card-deck-layout",
  opponentDeck: "opponent-deck",
  opponentCards: "opponent-cards",
  cardDeck: "card-deck",
  cardsHolder: "cards-holder",
  playerDeck: "player-deck",
  playerCards: "player-cards",
};

const gameIds = {
  playerExtraCards: "player-extra-cards",
  opponentExtraCards: "opponent-extra-cards",
};

const createCardDefault = { duration: 0, ease: "back" };
const shuffleDefault = { duration: 0, ease: "back" };
const restackDefault = { duration: 0.05, ease: "back" };
const addCardWinnerDefault = { duration: 0.25, ease: "back" };
const cardClickDefault = { duration: 0.3, ease: "power3" };
const warModeStartDefault = { duration: 1, ease: "back" };
const warModeEndDefault = { duration: 1, ease: "back" };
const winnerDefault = { duration: 0.5, ease: "bounce" };

const createCardTimeline = gsap.timeline({
  paused: true,
  defaults: {
    ...createCardDefault,
  },
  onComplete: function () {
    $("#start-game").show();
    $("#game-restart").hide();
    shuffle(cardsperDeck);
  },
});

const shuffleTimeline = gsap.timeline({
  paused: true,
  defaults: {
    ...shuffleDefault,
  },
  onComplete: () => hanldeShuffleAnimationEnd(),
});

const reStackPlayerTimeline = gsap.timeline({
  paused: true,
  defaults: { ...restackDefault },
  onComplete: () => {
    if (!checkWarCards("player") && !reStackOpponentTimeline.isActive()) {
      findPlayerFirstCard();
    }
  },
});

const reStackOpponentTimeline = gsap.timeline({
  paused: true,
  defaults: { ...restackDefault },
  onComplete: () => {
    if (cardsOnHand.player.length > 0 && !checkWarCards("player")) {
      findPlayerFirstCard();
    }

    if (
      !checkWarCards("opponent") &&
      cardsOnHand.warMode &&
      checkWarCards("player")
    ) {
      handleWarModeOpponentCards();
    }
  },
});

const warModeStartTimeline = gsap.timeline({
  paused: true,
  defaults: { ...warModeStartDefault },
  onComplete: () => {
    decideWinner().then((res) => {
      if (!res.isGameOver) {
        cardsOnHand.warCount += 1;

        if (
          cardsOnHand.warMode &&
          cardsOnHand.player.length > 0 &&
          !reStackOpponentTimeline.isActive()
        ) {
          findPlayerFirstCard();
        }

        if (
          cardsOnHand.player.length === 0 &&
          cardsOnHand.playerExtra.length > 0
        ) {
          reStackPlayerCards();
        }

        if (
          cardsOnHand.opponent.length === 0 &&
          cardsOnHand.opponentExtra.length > 0
        ) {
          reStackOpponentCards();
        }
      }
    });
  },
});

const warModeEndTimeline = gsap.timeline({
  paused: true,
  defaults: { ...warModeEndDefault },
  onComplete: () => { },
});

const playerCardClickTimeline = gsap.timeline({
  paused: true,
  defaults: { ...cardClickDefault },
  onComplete: function () {
    if (cardsOnHand.player.length === 0 && cardsOnHand.playerExtra.length > 0) {
      reStackPlayerCards();
    }

    if (!cardsOnHand.warMode) {
      handleOpponentCard();
    }

    if (checkWarCards("player")) {
      $(".warmode-instruction").remove();
      handleWarModeOpponentCards();
    }

    if (cardsOnHand.warMode) {
      if (!checkWarCards("player")) {
        findPlayerFirstCard();
      }
    }
  },
});

const opponentCardClickTimeline = gsap.timeline({
  paused: true,
  defaults: { ...cardClickDefault },
  onComplete: function () {
    if (compareCardsObj.opponent.length === 1 && !cardsOnHand.warMode) {
      compareCards(compareCardsObj);
    }

    if (
      getTotalWarCards() === compareCardsObj.opponent.length - 1 &&
      cardsOnHand.warMode
    ) {
      compareCards(compareCardsObj);
    }

    if (
      !cardsOnHand.warMode &&
      !reStackOpponentTimeline.isActive() &&
      !checkWarCards("player") &&
      cardsOnHand.opponent.length > 0
    ) {
      findPlayerFirstCard();
    }
  },
});

const addCardWinnerMasterTimeline = gsap.timeline({
  paused: true,
  onComplete: () => {
    checkPlayerCards();
  },
});

const winnerAnimationTimeline = gsap.timeline({
  paused: true,
  defaults: { ...winnerDefault },
});

const createCards = (cards) => {
  if (cards > 0) {
    createCards(cards - 1);
    $("<div>", { class: `cards card-${cards}` })
      .append(
        $("<div>", {
          class: "content",
        }).append([
          $("<div>", {
            class: "up",
          }).css("background-image", `url(${cardData[cards - 1].image})`),
          $("<div>", {
            class: "down",
          }),
        ])
      )
      .appendTo(`.${classes.cardsHolder}`);
  } else {
    gsap.delayedCall(0.01, addCardAnimation);
  }
};

const addCardAnimation = () => {
  for (let i = cardsperDeck; i > 0; i--) {
    createCardTimeline.to(`.card-${i}`, {
      x: Math.floor(i / 2),
      y: Math.floor(i / 4),
      opacity: 0,
      zIndex: i,
    });
  }
  createCardTimeline.play();
};

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const makeCardDataPerSuit = () => {
  return new Promise((resolve, reject) => {
    if (cardsperDeck / cardsPerSuit === suitType.length) {
      for (let i = 0; cardData.length < cardsperDeck; i++) {
        const cards = cardData.map((data) => data.id);
        const cardsID = cardData.map((data) => data.cardID);
        const random = randomIntFromInterval(1, cardsperDeck);
        const randomCardNumber = randomIntFromInterval(
          suitStarting,
          cardsPerSuit + 1
        );
        const randomType = randomIntFromInterval(0, suitType.length - 1);
        const cardId = `${suitType[randomType]}${randomCardNumber}`;
        if (!cards.includes(random) && !cardsID.includes(cardId)) {
          cardData = [
            ...cardData,
            {
              id: random,
              value: randomCardNumber,
              cardID: cardId,
              image: "./card-back.png",
              cardFront: `./card-front/${cardId}.png`,
            },
          ];
        }
      }
      resolve({ status: "success", message: "Cards data created" });
    } else {
      reject({ status: "failed", message: "Card Creation Failed" });
    }
  });
};

const restartGame = () => {
  cardData = [];
  compareCardsObj = {
    player: [],
    opponent: [],
  };

  cardsOnHand = {
    player: [],
    opponent: [],
    playerExtra: [],
    opponentExtra: [],
    warMode: false,
    warCount: 0,
  };
  $(`.${classes.opponentCards}`).empty();
  $(`.${classes.playerCards}`).empty();
  $(`#${gameIds.playerExtraCards}`).empty();
  $(`#${gameIds.opponentExtraCards}`).empty();
  $(`.${classes.cardsHolder}`).empty();
  removeWinnerAnimation();
  removeWarMode();
  makeCardDataPerSuit().then((res) => {
    createCards(cardsperDeck);
  });
  setTimeout(() => {
    shuffle(cardsperDeck);
  }, 10);
};

const findPlayerFirstCard = () => {
  if (cardsOnHand.player.length > 0) {
    setCardClickable(cardsOnHand.player[cardsOnHand.player.length - 1]);
  }
};

const hanldeShuffleAnimationEnd = () => {
  $("#start-game").hide();
  $("#game-restart").show();
  shuffleTimeline.restart();
  findPlayerFirstCard();
};

const shuffle = (cards) => {
  if (cards > 0) {
    if (cards % 2 === 0) {
      const playerDeck = document.querySelector(`.${classes.opponentCards}`);
      const target = document.querySelector(`.card-${cardData[cards - 1].id}`);
      const playerRct = playerDeck.getBoundingClientRect();
      const rect = target.getBoundingClientRect();
      playerDeck.append(target);
      shuffleTimeline
        .from(target, {
          x: rect.left - playerRct.left,
          y: rect.top - playerRct.top,
          rotateZ: 0,
          zIndex: cards,
        })
        .to(
          target,
          {
            x: Math.floor(cardsOnHand.player.length * suitType.length),
            y: 0,
            rotateZ: 360,
            rotateY: -0,
            transition: "transform",
            opacity: 1,
          },
          "<"
        )
        .to(
          target,
          {
            zIndex: cardsOnHand.opponent.length,
          },
          "<"
        );

      cardsOnHand.opponent = [...cardsOnHand.opponent, target];
    } else {
      const playerDeck = document.querySelector(`.${classes.playerCards}`);
      const playerRct = playerDeck.getBoundingClientRect();
      const target = document.querySelector(`.card-${cardData[cards - 1].id}`);
      const rect = target.getBoundingClientRect();
      playerDeck.append(target);
      shuffleTimeline
        .from(target, {
          x: rect.left - playerRct.left,
          y: rect.top - playerRct.top,
          rotateZ: 0,
          zIndex: cards,
        })
        .to(
          target,
          {
            x: -Math.floor(cardsOnHand.player.length * suitType.length),
            y: -0,
            rotateZ: 360,
            rotateY: -0,
            transition: "transform",
            opacity: 1,
          },
          "<"
        )
        .to(
          target,
          {
            zIndex: cardsOnHand.player.length,
          },
          "<"
        );
      cardsOnHand.player = [...cardsOnHand.player, target];
    }
    shuffleTimeline.play();
    shuffle(cards - 1);
  }
};

const handleCardClick = (event) => {
  const cardParent = event.target.parentNode.parentNode;
  const target = document.querySelector(`.${cardParent.classList[1]}`);
  const card = cardParent.classList[1].replace("card-", "");
  gsap
    .set(target, {
      //x: 0,
      rotateY: 0,
      rotateZ: 0,
      xPercent: 0,
      yPercent: 0,
      top: 0,
      left: 0,
      y: 0,
      // clearProps: "all"
    })
    .then(() => {
      handleCardClickAnimation(
        card,
        target,
        cardParent,
        playerCardClickTimeline,
        200,
        "player"
      );
    });

  compareCardsObj = {
    ...compareCardsObj,
    player: [...compareCardsObj.player, target],
  };

  if (cardsOnHand.warMode) {
    $(".warmode-instruction").text(
      `You need to play ${getTotalWarCards() - compareCardsObj.player.length + 1
      } cards to finish the war!`
    );

    removeCardsFromPlayers(compareCardsObj.player, compareCardsObj.opponent);
  }
  removeCardClickable(target);
};

const handleOpponentCard = () => {
  const cardParent = document.querySelector(`.${classes.opponentCards}`);
  const lastCard = cardParent.children[cardParent.children.length - 1];
  compareCardsObj = {
    ...compareCardsObj,
    opponent: [...compareCardsObj.opponent, lastCard],
  };
  if (lastCard) {
    const target = document.querySelector(`.${lastCard.classList[1]}`);
    const card = lastCard.classList[1].replace("card-", "");
    gsap
      .set(target, {
        // x: 0,
        rotateY: 0,
        rotateZ: 0,
        xPercent: 0,
        yPercent: 0,
        top: 0,
        left: 0,
        y: 0,
        // clearProps: "all"
      })
      .then(() => {
        handleCardClickAnimation(
          card,
          target,
          lastCard,
          opponentCardClickTimeline,
          -150,
          "opponent"
        );
      });
  }
};

const handleCardClickAnimation = (
  card,
  target,
  cardParent,
  timeline,
  xOffset,
  playerType
) => {
  const cardDeck = document.querySelector(`.${classes.cardsHolder}`);
  var cardContent = cardParent.querySelectorAll(".content");
  var cardBack = cardParent.querySelectorAll(".down");
  $(cardBack).css("background-image", `url(${cardData[card - 1].cardFront})`);

  const playerRct = cardDeck.getBoundingClientRect();
  const rect = target.getBoundingClientRect();
  cardDeck.append(target);

  timeline
    .from(target, {
      x: rect.left - playerRct.left,
      y: rect.top - playerRct.top,
      width: cardSize.playerSize.width,
      height: cardSize.playerSize.height,
    })
    .to(
      cardContent,
      {
        rotateY: 180,
        rotateZ: -180,
        transition: "transform",
      },
      "<"
    )
    .to(
      target,
      {
        zIndex:
          playerType === "player"
            ? compareCardsObj.player.length
            : compareCardsObj.opponent.length,
        rotation: "random(6 , -6)",
        x: xOffset,
        width: cardSize.deckSize.width,
        height: cardSize.deckSize.height,
      },
      "<"
    );
  timeline.play();
};

const removeCardsFromPlayers = (playerCards, opponentCards) => {
  cardsOnHand.player = cardsOnHand.player.filter(
    (cards) => playerCards.indexOf(cards) === -1
  );
  cardsOnHand.opponent = cardsOnHand.opponent.filter(
    (cards) => opponentCards.indexOf(cards) === -1
  );
};

const compareCards = async (cards) => {
  const mergedCards = cards.player.concat(cards.opponent);

  const playerValue = cards.player
    .map((a) => cardData[Number(a.classList[1].replace("card-", "")) - 1].value)
    .reduce((a, b) => a + b);

  const opponentValue = cards.opponent
    .map((a) => cardData[Number(a.classList[1].replace("card-", "")) - 1].value)
    .reduce((a, b) => a + b);

  const playerCards = cards.player;

  const opponentCards = cards.opponent;

  const extraParentOpp = document.querySelector(`.${classes.opponentCards}`);

  const opponentDeck = document.querySelector(`.${classes.opponentDeck}`);

  const playerDeck = document.querySelector(`.${classes.playerDeck}`);

  const extraParentPlayer = document.querySelector(`.${classes.playerCards}`);

  removeCardsFromPlayers(playerCards, opponentCards);

  if (playerValue > opponentValue) {
    if (playerDeck.querySelector(`.${gameIds.playerExtraCards}`) == null) {
      $("<div>", {
        id: `${gameIds.playerExtraCards}`,
        class: `${gameIds.playerExtraCards}`,
      }).insertBefore(extraParentPlayer);
    }

    cardsOnHand.playerExtra = [
      ...cardsOnHand.playerExtra,
      ...playerCards,
      ...opponentCards,
    ];
  }

  if (opponentValue > playerValue) {
    if (opponentDeck.querySelector(`.${gameIds.opponentExtraCards}`) == null) {
      $("<div>", {
        id: `${gameIds.opponentExtraCards}`,
        class: `${gameIds.opponentExtraCards}`,
      }).insertAfter(extraParentOpp);
    }

    cardsOnHand.opponentExtra = [
      ...cardsOnHand.opponentExtra,
      ...playerCards,
      ...opponentCards,
    ];
  }

  if (playerValue === opponentValue) {
    cardsOnHand.warMode = true;
    executeWarMode();
  } else {
    if (cardsOnHand.warMode) {
      cardsOnHand.warMode = false;
      cardsOnHand.warCount = 0;
      removeWarMode();
    }

    addCardWinnerMasterTimeline.add("addcardWinnerStart");

    mergedCards?.forEach((singleCard, index) => {
      const cardRect = singleCard.getBoundingClientRect();
      if (playerValue > opponentValue) {
        const extraCardParentPlayer = document.querySelector(
          `#${gameIds.playerExtraCards}`
        );
        addCardWinnerMasterTimeline.add(
          addCardsToWinner(
            singleCard,
            cardRect,
            extraCardParentPlayer,
            cardsOnHand.playerExtra.length + index,
            "player"
          ),
          "addcardWinnerStart"
        );
      } else {
        const extraCardParentOpponent = document.querySelector(
          `#${gameIds.opponentExtraCards}`
        );

        addCardWinnerMasterTimeline.add(
          addCardsToWinner(
            singleCard,
            cardRect,
            extraCardParentOpponent,
            cardsOnHand.opponentExtra.length + index,
            "opponent"
          ),
          "addcardWinnerStart"
        );
      }
      addCardWinnerMasterTimeline.play();
    });
  }
};

const addCardsToWinner = (target, rect, extraCardParent, index, winner) => {
  compareCardsObj = {
    player: [],
    opponent: [],
  };

  const oldCardX = gsap.getProperty(target, "x");
  const oldCardY = gsap.getProperty(target, "y");

  extraCardParent.append(target);

  const newRect = target.getBoundingClientRect();

  return gsap
    .timeline({ paused: true, defaults: { ...addCardWinnerDefault } })
    .from(target, {
      x: rect.left - newRect.left + oldCardX,
      y: rect.top - newRect.top + oldCardY,
      rotation: "random(11 , -5)",
      ease: "power2.out",
      rotateY: winner === "opponent" ? -180 : 0,
    })
    .to(
      target,
      {
        x: "-50%",
        y: "-50%",
        top: "50%",
        left: "50%",
        rotation: "random(11 , -5)",
        rotateY: winner === "opponent" ? -180 : 0,
        ease: "power2.out",
        zIndex: index,
        width: cardSize.playerSize.width,
        height: cardSize.playerSize.height,
      },
      "<"
    )
    .play();
};

const checkPlayerCards = () => {
  decideWinner().then((res) => {
    if (!res.isGameOver) {
      if (cardsOnHand.player.length === 0) {
        reStackPlayerCards();
      }

      if (cardsOnHand.opponent.length === 0) {
        reStackOpponentCards();
      }
    }
  });
};

const reStackPlayerCards = () => {
  const deck = document.querySelector(`.${classes.playerCards}`);

  cardsOnHand.player = cardsOnHand.playerExtra;

  cardsOnHand.player.reverse().forEach((singleCard, index) => {
    const cardRect = singleCard.getBoundingClientRect();
    deck.append(singleCard);
    addRestackAnimation(
      singleCard,
      cardRect,
      deck,
      cardsOnHand.playerExtra.length - index,
      cardsOnHand.playerExtra.length + index,
      reStackPlayerTimeline,
      -Math.floor(index * suitType.length)
    );
  });

  cardsOnHand.playerExtra = [];
};

const reStackOpponentCards = () => {
  const deck = document.querySelector(`.${classes.opponentCards}`);

  cardsOnHand.opponent = [
    ...cardsOnHand.opponent,
    ...cardsOnHand.opponentExtra,
  ];

  cardsOnHand.opponent.reverse().forEach((singleCard, index) => {
    const cardRect = singleCard.getBoundingClientRect();
    deck.append(singleCard);
    addRestackAnimation(
      singleCard,
      cardRect,
      deck,
      cardsOnHand.opponentExtra.length - index,
      cardsOnHand.opponentExtra.length + index,
      reStackOpponentTimeline,
      Math.floor(index * suitType.length)
    );
  });
  cardsOnHand.opponentExtra = [];
};

const addRestackAnimation = (
  target,
  rect,
  extraCardParent,
  index,
  to_index,
  reStackTimeline,
  reStackX
) => {
  extraCardParent.append(target);
  var cardContent = target.querySelectorAll(".content");
  const newRect = target.getBoundingClientRect();

  gsap.set(target, {
    x: 0,
    rotateY: 0,
    rotateZ: 0,
    xPercent: 0,
    yPercent: 0,
    top: 0,
    left: 0,
    y: 0,
  });

  reStackTimeline
    .from(target, {
      x: rect.left - newRect.left,
      y: rect.top - newRect.top,
      xPercent: -50,
      left: "50%",
      yPercent: -50,
      top: "50%",
      zIndex: index,
    })
    .to(
      cardContent,
      {
        rotateY: 0,
        rotateZ: 0,
        transition: "transform",
      },
      "<"
    )
    .to(
      target,
      {
        x: reStackX,
        zIndex: to_index,
      },
      "<"
    );

  reStackTimeline.play();
};

const setCardClickable = (card) => {
  card.onclick = handleCardClick;
  $(card).addClass("clickable");
};

const removeCardClickable = (card) => {
  card.onclick = "";
  $(card).removeClass("clickable");
};

const removeElement = (element) => {
  if (typeof element === "string") {
    $(element).remove();
  }
};

const executeWarMode = () => {
  const cardDeckLayout = $(`.${classes.cardDeckLayout}`);

  $("body").addClass("warmode");

  $("#table-top").attr("src", "./assets/images/game/game-table-red-bg.png");

  // $("<h3>", { id: "warMode_Heading" }).text("WAR").appendTo(cardDeckLayout);

  const warModeSec = $("<div>", { id: "warMode_Heading" });

  $("<img/>", {
    id: "warMode_Image",
    src: `./game_war_image.svg`
  }).appendTo(warModeSec);

  warModeSec.appendTo(cardDeckLayout);

  $("<div>", { class: "warmode-instruction" })
    .append("<p>", { class: "" })
    .text(`You need to play ${warCardsCount} cards to finish the war!`)
    .appendTo(cardDeckLayout);

  warModeStartTimeline
    .fromTo(
      "#warMode_Image",
      {
        scale: 0,
        translateX: "-50%",
        translateY: "-30%",
      },
      {
        scale: 0.7,
        translateX: "-50%",
        translateY: "-50%",
        duration: 2,
        onComplete: function () {
          removeElement("#warMode_Heading");
          removeElement("#warMode_Image");
        },
      }
    )
    .fromTo(
      ".warmode-instruction",
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
      },
      "<-50%"
    );

  warModeStartTimeline.play();
};

const removeWarMode = () => {
  $("body").removeClass("warmode");
  $("#table-top").attr("src", "./assets/images/game/game-table-bg.png");
  removeElement("#warMode_Heading");
  removeElement(".warmode-instruction");
  warModeEndTimeline.play();
};

const handleWarModeOpponentCards = () => {
  if (cardsOnHand.opponent.length >= warCardsCount) {
    for (let i = 0; i < warCardsCount; i++) {
      handleOpponentCard();
    }
  } else {
    const totalOpponentCards = cardsOnHand.opponentExtra.concat(
      cardsOnHand.opponent
    );

    if (totalOpponentCards.length >= warCardsCount) {
      reStackOpponentCards();
    } else {
      console.log("opponent lose");
      winnerAnimation("player");
    }
  }
};

const checkWarCards = (playerType) => {
  if (cardsOnHand.warMode) {
    const result =
      warCardsCount * cardsOnHand.warCount + 1 ===
      compareCardsObj[playerType].length;

    return result;
  } else {
    return false;
  }
};

const getTotalWarCards = () => {
  if (cardsOnHand.warCount > 0) {
    return warCardsCount * cardsOnHand.warCount;
  }

  return null;
};

const decideWinner = () => {
  return new Promise((resolve, reject) => {
    const totalPlayerCards = cardsOnHand.player.concat(cardsOnHand.playerExtra);
    const totalOpponentCards = cardsOnHand.opponent.concat(
      cardsOnHand.opponentExtra
    );

    let isGameOver = false;
    let winner = null;

    if (cardsOnHand.warMode) {
      if (
        (totalPlayerCards.length === 0 && totalOpponentCards.length > 0) ||
        (totalPlayerCards.length < warCardsCount &&
          totalOpponentCards.length > warCardsCount)
      ) {
        isGameOver = true;
        winner = "opponent";
      }

      if (
        (totalOpponentCards.length === 0 && totalPlayerCards.length > 0) ||
        (totalPlayerCards.length > warCardsCount &&
          totalOpponentCards.length < warCardsCount)
      ) {
        isGameOver = true;
        winner = "player";
      }

      if (
        (totalOpponentCards.length === 0 && totalPlayerCards.length === 0) ||
        (totalPlayerCards.length < warCardsCount &&
          totalOpponentCards.length < warCardsCount)
      ) {
        isGameOver = true;
        winner = "draw";
      }
    } else {
      if (totalPlayerCards.length === 0 && totalOpponentCards.length > 0) {
        isGameOver = true;
        winner = "opponent";
      }

      if (totalOpponentCards.length === 0 && totalPlayerCards.length > 0) {
        isGameOver = true;
        winner = "player";
      }

      if (totalOpponentCards.length === 0 && totalPlayerCards.length === 0) {
        isGameOver = true;
        winner = "draw";
      }
    }
    if (winner) {
      winnerAnimation(winner);
    }

    resolve({ isGameOver: isGameOver, winner: winner });
  });
};

const winnerAnimation = (winner) => {
  const cardDeckLayout = $(`.${classes.cardDeckLayout}`);

  const winnerText =
    winner !== "draw"
      ? `You have ${winner === "player" ? "won" : "Lost"}`
      : "Draw";

  const winImage =
    winner !== "draw"
      ? `${winner === "player" ? "/winner_trophy.png" : "/player_lost.png"} `
      : "/match_draw.png";

  $("<div>", { id: "winner_wrapper" })
    .append($("<h1>", { class: "winner_text" }).text(winnerText))
    .append($("<img>").attr("src", `./${winImage} `))
    .append($("<button>").click(restartGame).text("Start a new game"))
    .appendTo(cardDeckLayout);

  winnerAnimationTimeline
    .fromTo(
      "#winner_wrapper",
      {
        scale: 0,
      },
      {
        scale: 1,
      }
    )
    .play();
};

const removeWinnerAnimation = () => {
  $("#winner_wrapper").remove();
};

const CardGame = () => {

  const [rules, setRules] = useState(false);

  const closeRulesModal = () => {
    setRules(false);
  };


  const [play, setPlay] = useState(true);
  const [gameState, setGameState] = useState({
    loading: true,
    errorMessage: null,
    error: false,
  });

  useEffect(() => {
    if (cardData.length > 0) {
      createCards(cardsperDeck);
    } else {
      makeCardDataPerSuit()
        .then((data) => {
          setGameState((prevState) => {
            return { ...prevState, loading: false };
          });
        })
        .catch((error) => {
          setGameState((prevState) => {
            return {
              ...prevState,
              loading: false,
              error: true,
              errorMessage: error.message,
            };
          });
        });
    }
  }, [gameState.loading]);

  const changePlay = (e) => {
    e.preventDefault();
    if (play) {

    } else {

    }
    setPlay(!play);
  }


  return (
    <>
      <div className="layout-container">
        <section className="game-page-sec">
          <div className="game-header-img-sec">
            <img
              src={"./assets/images/game/game-top-bg.png"}
              alt=""
              className="game-header-bg-img"
            />
          </div>
          <div className="game-body-img-sec">
            <img
              id="table-top"
              src={"./assets/images/game/game-table-bg.png"}
              className="game-body-bg-img"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="game-bot-mooby-img-sec">
                  <img
                    src={"./assets/images/game/mooby.svg"}
                    className="game-bot-mooby-img"
                    alt=""
                  />
                </div>
                <div className="card-game-main">
                  {gameState.loading ? null : cardData.length > 0 ? (
                    <>
                      <div className={classes.cardDeckLayout}>
                        <div className={classes.opponentDeck}>
                          <div className={classes.opponentCards}></div>
                          <div
                            id={gameIds.opponentExtraCards}
                            className={gameIds.opponentExtraCards}
                          ></div>
                        </div>
                        <div className={classes.cardDeck}>
                          <div className={classes.cardsHolder}></div>
                        </div>
                        <div className={classes.playerDeck}>
                          <div
                            id={gameIds.playerExtraCards}
                            className={gameIds.playerExtraCards}
                          ></div>
                          <div className={classes.playerCards}></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    gameState.error && (
                      <div className={classes.cardDeckLayout}>
                        <div className="error_container">
                          <div className="error">
                            <h3 className="error_text">{gameState.errorMessage != null ? gameState.errorMessage : "Something went wrong"}</h3>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          {!play ?
            <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", background: "rgba(0,0,0,0.2)", zIndex: 2 }}></div>
            : null
          }
          <div className="game-action-top-left-sec">
            <div className="game-action-top-left-card">
              <button className="game-top-left-btn" onClick={changePlay}>
                <img
                  src={play ? "./assets/images/game/pause.svg" : "./assets/images/game/play.svg"}
                  className="game-pause-img"
                  alt=""
                />
              </button>
              <button
                className="game-top-left-btn"
                id="game-restart"
                onClick={() => { restartGame(); setPlay(true) }}
              >
                <img
                  src={"./assets/images/game/restart.svg"}
                  className="game-restart-img"
                  alt=""
                />
              </button>
              <a className="game-top-left-btn"
                href="../game_home.html">
                <img
                  src={"./assets/images/game/quit.svg"}
                  className="game-quit-img"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="game-action-bottom-left-sec">
            <div className="game-action-bottom-left-card">
              {/* <button className="game-bottom-left-btn">
                <img
                  src={"./assets/images/game/settings.svg"}
                  className="game-settings-img"
                  alt=""
                />
              </button> */}
              <button className="game-bottom-left-btn" onClick={() => setRules(true)}>
                <img
                  src={"./assets/images/game/menu.svg"}
                  className="game-menu-img"
                  alt=""
                />
              </button>
            </div>
          </div>
          <div className="game-action-bottom-right-sec">
            <div className="game-action-bottom-right-card">
              <a className="game-bottom-right-btn"
                href="../index.html">
                <img
                  src={"./assets/images/game/home.svg"}
                  className="game-home-img"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="game-player-profile-img-sec">
            <img
              src={"./assets/images/game/player-profile.svg"}
              className="game-player-profile-img"
              alt=""
            />
          </div>
        </section>
      </div>
      <RulesModal
                rules={rules}
                closeRulesModal={closeRulesModal}
                setRules={setRules}
            />
    </>
  );
};

export default CardGame;
