import React from "react";
import { Form, Button, Image, Modal, Container, Row, Col } from "react-bootstrap";

const RulesModal = (props) => {
  return (
    <>
      <Modal
        className="modal-dialog-center rules-modal"
        size="lg"
        centered
        show={props.rules}
        onHide={props.closeRulesModal}
      >
        <Modal.Header>
          {/* <Modal.Title>Add Properties</Modal.Title> */}
          <div className="game-rules-heading-img-sec">
            <img
              src={"./assets/images/game-rules/game-rules-heading.svg"}
              className="game-rules-heading-img"
              alt=""
            />
          </div>
          <Button className="modal-close" onClick={() => props.closeRulesModal()}>
            <Image
              className="close-icon"
              src={"./assets/images/game-rules/modal-close.svg"
              }
            />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-12 col-xl-6 col-lg-6">
                <div className="game-rule-content-card">
                  <img
                    src={"./assets/images/game-rules/game-rules-content-bg.svg"}
                    className="game-rules-content-img"
                    alt=""
                  />
                  {/* <img
                      src={"./assets/images/game-rules/game-rules-content-bg.svg"}
                      className="game-rules-content-img"
                      alt=""
                    /> */}
                  <p>A video game UI is an interface with components that help players navigate</p>
                </div>
                <div className="game-rule-content-card">
                  <img
                    src={"./assets/images/game-rules/game-rules-content-bg.svg"}
                    className="game-rules-content-img"
                    alt=""
                  />
                  <p>A video game UI is an interface with components that help players navigate</p>
                </div>
                <div className="game-rule-content-card">
                  <img
                    src={"./assets/images/game-rules/game-rules-content-bg.svg"}
                    className="game-rules-content-img"
                    alt=""
                  />
                  <p>A video game UI is an interface with components that help players navigate</p>
                </div>
              </div>
              <div className="col-md-12 col-xl-6 col-lg-6">
                <div className="game-rule-content-card">
                  <img
                    src={"./assets/images/game-rules/game-rules-content-bg.svg"}
                    className="game-rules-content-img"
                    alt=""
                  />
                  <p>A video game UI is an interface with components that help players navigate</p>
                </div>
                <div className="game-rule-content-card">
                  <img
                    src={"./assets/images/game-rules/game-rules-content-bg.svg"}
                    className="game-rules-content-img"
                    alt=""
                  />
                  <p>A video game UI is an interface with components that help players navigate</p>
                </div>
                <div className="game-rule-content-card">
                  <img
                    src={"./assets/images/game-rules/game-rules-content-bg.svg"}
                    className="game-rules-content-img"
                    alt=""
                  />
                  <p>A video game UI is an interface with components that help players navigate</p>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default RulesModal;